import { s, c, t } from '../val.config';

const navigationItemSchema = s.union(
  'type',
  s.object({ type: s.literal('link'), title: s.string(), url: s.string() }),
  s.object({ type: s.literal('contactButton'), text: s.string() })
);

export const schema = s.object({
  navigationItems: s.array(navigationItemSchema),
  socialMediaLinks: s.array(s.object({ title: s.string(), url: s.string() })),
});

export default c.define('/content/header.val.ts', schema, {
  navigationItems: [
    { type: 'link', url: '/digital-produktutvikling', title: 'Om oss' },
    { type: 'link', url: '/prosjekter', title: 'Arbeider' },
    { type: 'link', url: '/jobb', title: 'Karriere' },
    { type: 'link', url: '/menneskene', title: 'Folk' },
    { type: 'link', url: '/handboka', title: 'Håndbok' },
    { type: 'contactButton', text: 'Kontakt' },
  ],
  socialMediaLinks: [
    { title: 'blogg', url: 'https://blogg.blank.no/' },
    { title: 'facebook', url: 'https://www.facebook.com/blankoslo' },
    { title: 'instagram', url: 'https://www.instagram.com/blankoslo/' },
    { title: 'linkedIn', url: 'https://no.linkedin.com/company/blankas' },
    { title: 'kjøregår', url: '/events' },
  ],
});
